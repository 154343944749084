<template>
  <div style="height: 100vh; overflow: hidden auto">
    <!-- <navVue class="" :route="getBackRouteData" :backBtn="true"></navVue> -->
    <div
      class="w-100 headerSection d-flex align-items-center justify-content-between"
    >
      <router-link class="navRoute" :to="getBackRouteData.path">
        <svg
          class="navbar__icon navbar__icon--left mr-3"
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.08004 0.993291C6.75337 0.666624 6.22671 0.666624 5.90004 0.993291L0.360039 6.53329C0.100039 6.79329 0.100039 7.21329 0.360039 7.47329L5.90004 13.0133C6.22671 13.34 6.75337 13.34 7.08004 13.0133C7.40671 12.6866 7.40671 12.16 7.08004 11.8333L2.25337 6.99996L7.08671 2.16662C7.4067 1.84662 7.40671 1.31329 7.08004 0.993291Z"
            fill="white"
          />
        </svg>
        {{ getBackRouteData.name }}
      </router-link>
      <Button
        :is-loading="isDesignSaveLoading"
        @click="saveHtmlDesign"
        class="saveEditorBtn"
      >
        Save Design
      </Button>
    </div>
    <div dir="ltr" class="EditorPage">
      <p v-if="!showEditor" class="headTitle">
        Loading your editor please wait
      </p>
      <!-- <div class="px-5 py-2 text-right w-100">
        
      </div> -->
      <div
        class=""
        id="editor-container"
        ref="editorContainer"
        style="height: calc(100vh - 90px)"
      ></div>
    </div>
    <!-- Your edit page content -->
  </div>
</template>
<script>
// import navVue from "../Dashboard/components/nav.vue";
import axios from "axios";
import Button from "../Dashboard/Layout/Button.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    // navVue,
    Button,
  },
  data() {
    return {
      productToolURL: process.env.VUE_APP_UNLAYER_PRODUCT_TOOL_URL,
      projectId: process.env.VUE_APP_UNLAYER_PROJECT_ID,
      showEditor: false,
      scriptLoaded: false,
      emailTemplateData: null,
      isEmailTemplateLoading: false,
      isDesignSaveLoading: false,
      unlayer: window.unlayer,
      mergeTags: {
        leadsData: {
          name: "Lead Data",
          mergeTags: {
            lead_name: {
              name: "Full Name",
              value: "{{lead.full_name}}",
              Sample: "John Doe",
            },
            email: {
              name: "Email",
              value: "{{lead.email}}",
              Sample: "Sample@email.com",
            },
            phone_number: {
              name: "Phone Number",
              value: "{{lead.phone_number}}",
              Sample: "+19893937293",
            },
            address1: {
              name: "Address 1",
              value: "{{lead.address1}}",
              Sample: "Address 1",
            },
            address2: {
              name: "Address 2",
              value: "{{lead.address2}}",
              Sample: "Address 2",
            },
            city: {
              name: "City",
              value: "{{lead.city}}",
              Sample: "City",
            },
            state: {
              name: "State",
              value: "{{lead.state}}",
              Sample: "State",
            },
            country: {
              name: "Country",
              value: "{{lead.country}}",
              Sample: "Country",
            },
            zip_code: {
              name: "Zip Code",
              value: "{{lead.zip_code}}",
              Sample: "Zip Code",
            },
            website: {
              name: "Website",
              value: "{{lead.website}}",
              Sample: "Website",
            },
            organisation: {
              name: "Organization",
              value: "{{lead.organisation}}",
              Sample: "Organization",
            },
            result_link: {
              name: "Result Link",
              value: "{{lead.result_link}}",
              Sample: "Result Link",
            },
            score: {
              name: "Score",
              value: "{{lead.score}}",
              Sample: "Result Link",
            },
          },
        },
        OTP: {
          name: "OTP",
          value: "{{$otp}}",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getCurrEmailDesignEditData"]),
    getCurrEmailData() {
      return this.getCurrEmailDesignEditData;
    },
    getCustomDesignJson() {
      if (
        Object.entries(this.$route.query).length &&
        this.$route.query.editCustomDesign
      ) {
        return this.getCurrEmailData.designData.design_json
          ? JSON.parse(this.getCurrEmailData.designData.design_json)
          : null;
      } else {
        return this.emailTemplateData
          ? this.emailTemplateData.design_json
          : null;
      }
    },

    isCustomDesignEditMode() {
      return Object.entries(this.$route.query).length &&
        this.$route.query.editCustomDesign
        ? true
        : false;
    },
    getBackRouteData() {
      if (
        this.isCustomDesignEditMode &&
        typeof this.getCurrEmailData == "object"
      ) {
        let obj = {
          name: "Templates",
          path: this.getCurrEmailData?.redirectPath,
        };
        return obj;
      } else {
        return {
          name: "Templates",
          path: `/manage/emails/templates`,
        };
      }
    },
  },
  async mounted() {
    await this.getAllQuizQuestionsData();

    if (this.isCustomDesignEditMode) {
      if (this.isScriptLoaded()) {
        this.initializeEditor();
      } else {
        this.loadUnlayerScript();
      }
    } else {
      await this.getCurrentEditorData();
    }
  },
  beforeDestroy() {
    this.removeUnlayerScript();
  },
  methods: {
    async getAllQuizQuestionsData() {
      try {
        const response = await axios.get(`/api/v1/unlayer/email/variables`);

        if (response.status != 200 || response.data.data.length === 0) {
          return false;
        }

        const newObj = response.data.data.quizzes.reduce((acc, item) => {
          acc[item.quiz] = {
            name: item.quiz,
            mergeTags: item.questions,
          };
          return acc;
        }, {});

        let QuestionsObj = {
          name: "Quizzes",
          mergeTags: newObj,
        };
        const customInputs = {
          name: "Custom Inputs",
          mergeTags: response.data.data.custom_inputs,
        };

        this.mergeTags = { ...this.mergeTags, QuestionsObj, customInputs };

        return true;
      } catch (error) {
        this.$toasted.show("Error Occured!", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      }
    },
    ...mapMutations(["updateCurrEmailDesignEdit"]),
    async getCurrentEditorData() {
      try {
        this.isEmailTemplateLoading = true;
        const response = await this.fetchData({
          axiosMethod: "get",
          resource: "email_templates",
          method: "show",
          id: this.$route.params.id,
        });
        if (response.status == "success") {
          this.emailTemplateData = response?.data;
          if (this.emailTemplateData) {
            if (this.isScriptLoaded()) {
              this.initializeEditor();
            } else {
              this.loadUnlayerScript();
            }
          }
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          throw error;
        }
      } finally {
        this.isEmailTemplateLoading = false;
      }
    },
    ...mapActions(["fetchData"]),
    isScriptLoaded() {
      // Check if the script is already loaded in the head
      return (
        document.querySelector(
          'script[src="//editor.unlayer.com/embed.js"]'
        ) !== null
      );
    },
    loadUnlayerScript() {
      if (!this.scriptLoaded) {
        const script = document.createElement("script");
        script.src = "//editor.unlayer.com/embed.js";
        script.onload = this.initializeEditor;
        document.head.appendChild(script);
        this.scriptLoaded = true;
      }
    },
    async initializeEditor() {
      if (typeof this.getCurrEmailData != "object") {
        this.$toasted.show("Error Occured!", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        return true;
      }

      // const  {tool,tool2} = require('./customTool')
      // Register the custom product tool
      const url = this.productToolURL;
      const projectId = this.projectId;
      window.editor = window.unlayer.createEditor({
        id: "editor-container",
        displayMode: "email",
        locale: "en",
        projectId: projectId,
        mergeTags: this.mergeTags,
        customJS: [url],
        customCSS: [],
   
        appearance: {
          panels: {
      tools: {
        collapsible: false
      }
    },
          loader: {
            url: "https://quizell.com/cdn/shop/files/header-logo-new.svg?v=1711790205&width=120",
          },
        },
      });

      await window.editor.loadDesign(this.getCustomDesignJson);
      this.showEditor = true;
    },
    async saveHtmlDesign() {
      this.isDesignSaveLoading = true;
      let th = this;

      await window.editor.exportHtml(async function (data) {
        if (th.isCustomDesignEditMode) {
          let { design, html } = data;
          let obj = { ...th.getCurrEmailDesignEditData };
          obj.designData.design_html = html;
          obj.designData.design_json = JSON.stringify(design);

          await th.updateCurrEmailDesignEdit(obj);
          th.$router.push(`${obj.redirectPath}?completedCustomDesign=true&EmailEditId=${th.$route.query.EmailEditId}`);

          th.isDesignSaveLoading = false;
        } else {
          // design json

          th.emailTemplateData.design_json = data.design;

          try {
            const response = await th.fetchData({
              axiosMethod: "put",
              resource: "email_templates",
              method: "update",
              id: th.emailTemplateData.id,
              params: th.emailTemplateData,
            });

            if (response.status == "success") {
              th.$toasted.show("Design successfully updated!", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });

              th.$router.push(`/manage/emails/templates`);
            }
          } catch (error) {
            if (error) {
              th.$toasted.show("Design successfully updated!", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
              throw error;
            }
          } finally {
            th.isDesignSaveLoading = true;
          }
        }
      });
    },
    removeUnlayerScript() {
      // Remove the script when navigating away from the page
      const scriptElement = document.querySelector(
        'script[src="//editor.unlayer.com/embed.js"]'
      );
      if (scriptElement) {
        scriptElement.remove();
        this.scriptLoaded = false;
      }
    },
  },
};
</script>
<style scoped>
.headTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 60px;
  text-align: center;
  color: #161e34;
}
.EditorPage {
  margin-top: 60px;
  padding: 20px 0;
  min-height: calc(100% - 60px);
}

.headerSection {
  height: 60px;
  position: fixed;
  top: 0;
  background: #4d1b7e;
  padding: 8px 16px;
}

.saveEditorBtn {
  color: #fff;
  background: transparent;
  outline: 1px solid #fff;
  font-weight: 700;
}
.saveEditorBtn:hover {
  color: #4d1b7e;
  background: #fff;
  outline: none;
}

.navRoute {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.navRoute:hover {
  color: #fff;
  text-decoration: none;
}
</style>
